<template>
    <uw-content title="我的任务" padding="10px">

        <!-- 分类 -->
        <template slot="prefix">
            <el-button size="mini" :type="search.status == null ? 'primary' : 'text'" @click="search.status = null; TableSearch()">全部</el-button>
            <el-button size="mini" :type="search.status == 0 ? 'primary' : 'text'" @click="search.status = 0; TableSearch()">未开始</el-button>
            <el-button size="mini" :type="search.status == 1 ? 'primary' : 'text'" @click="search.status = 1; TableSearch()">进行中</el-button>
            <el-button size="mini" :type="search.status == 5 ? 'primary' : 'text'" @click="search.status = 5; TableSearch()">已完成</el-button>
            <el-button size="mini" :type="search.status == 4 ? 'primary' : 'text'" @click="search.status = 4; TableSearch()">已关闭</el-button>
            <el-button size="mini" :type="search.status == 2 ? 'warning' : 'text'" @click="search.status = 2; TableSearch()">已暂停</el-button>
            <el-button size="mini" :type="search.status == 6 ? 'warning' : 'text'" @click="search.status = 6; TableSearch()">已取消</el-button>
        </template>

        <!-- 菜单 -->
        <template slot="suffix">
            <el-dropdown trigger="click">
                <el-button type="primary" size="mini">批量管理<i class="el-icon-arrow-down el-icon--right"></i></el-button>
                <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item @click.native.stop="TaskDeleteMultiple()">批量删除</el-dropdown-item>
                    <el-dropdown-item @click.native.stop="TaskFlowStartMultiple()" divided>批量开始</el-dropdown-item>
                    <el-dropdown-item @click.native.stop="TaskFlowSuspendMultiple()">批量暂停</el-dropdown-item>
                    <el-dropdown-item @click.native.stop="TaskFlowCancelMultiple()">批量取消</el-dropdown-item>
                    <el-dropdown-item @click.native.stop="TaskFlowCloseMultiple()">批量关闭</el-dropdown-item>
                </el-dropdown-menu>
            </el-dropdown>
        </template>

        <!-- 表格 -->
        <uw-table>

            <!-- 检索 -->
            <template slot="search">
                <!-- 延期状态 -->
                <el-select v-model="search.is_overdue" @change="TableSearch()" placeholder="是否已延期?" size="mini" clearable filterable>
                    <el-option :value="0" label="未延期"></el-option>
                    <el-option :value="1" label="已延期"></el-option>
                </el-select>

                <!-- 任务名称 -->
                <el-input @input="$lodash.debounce(TableSearch, 500)" v-model="search.name" clearable  size="mini" placeholder="任务名称"></el-input>
            </template>

            <!-- 按钮 -->
            <template slot="button">
                <el-button type="primary" size="mini" @click="$refs.xTable.print()" >打印</el-button>
                <el-button type="primary" size="mini" @click="$refs.xTable.openExport()" >导出</el-button>
            </template>

            <!-- 数据 -->
            <template slot="data">
                <vxe-table
                    ref="xTable"
                    :data="task"
                    :loading="loading"
                    :export-config="{}"
                    :print-config="{}"
                    height="100%"
                    :row-config="{keyField: 'id'}"
                    :tree-config="{transform: true, childrenField: 'children', rowField: 'id', parentField: 'parent', indent: 10, expandAll: true}">

                    <!-- 复选框 -->
                    <vxe-column type="checkbox" width="45" fixed="left"></vxe-column>
                    
                    <!-- 重要程度 -->
                    <vxe-column width="120" field="p" title="重要程度">
                        <template #default="{ row }">
                            <el-tag v-if="row.p == 1" size="small" effect="plain" type="danger">P1 非常重要</el-tag>
                            <el-tag v-if="row.p == 2" size="small" effect="plain" type="warning">P2 重要</el-tag>
                            <el-tag v-if="row.p == 3" size="small" effect="plain" type="primary">P3 次要</el-tag>
                            <el-tag v-if="row.p == 4" size="small" effect="plain" type="success">P4 一般</el-tag>
                        </template>
                    </vxe-column>

                    <!-- 任务阶段 -->
                    <vxe-column width="140" field="status" title="任务阶段">
                        <template #default="{ row }">
                            <el-tag size="small" v-if="row.status == 0" effect="plain" type="primary">未开始</el-tag>
                            <el-tag size="small" v-if="row.status == 1" effect="plain" type="primary">进行中</el-tag>
                            <el-tag size="small" v-if="row.status == 2" effect="plain" type="warning">已暂停</el-tag>
                            <el-tag size="small" v-if="row.status == 4" effect="plain" type="info">已关闭</el-tag>
                            <el-tag size="small" v-if="row.status == 5" effect="plain" type="success">已完成</el-tag>
                            <el-tag size="small" v-if="row.status == 6" effect="plain" type="info">已取消</el-tag>
                            <el-tag size="small" v-if="row.is_overdue == 1" effect="plain" type="danger" style="margin-left: 5px;">已延期</el-tag>
                        </template>
                    </vxe-column>

                    <!-- 任务名称 -->
                    <vxe-column min-width="200" field="name" title="任务名称" tree-node>
                        <template #default="{ row }">
                            <el-link :style="{ color: row.name_color }" @click="$refs.taskIndex.Open(row.id)">{{ row.name }}</el-link>
                        </template>
                    </vxe-column>

                    <!-- 管理项目 -->
                    <vxe-column min-width="240" field="project" title="项目名称">
                        <template #default="{ row }">
                            <el-link @click="$refs.projectsDrawer.Open(row.project.id)" type="primary">{{ row.project.number }}-{{ row.project.name }}</el-link>
                        </template>
                    </vxe-column>

                    <!-- 指派给 -->
                    <vxe-column width="90" field="now_assign_user_name" title="指派给" :visible="true"></vxe-column>

                    <!-- 参与人员 -->
                    <vxe-column width="80" field="now_assign" title="参与人员" :visible="true">
                        <template #default="{ row }">
                            <el-tooltip placement="top" :content="row.assign_user_name.toString()">
                                <el-link icon="el-icon-user">{{ row.assign.length }}</el-link>
                            </el-tooltip>
                        </template>
                    </vxe-column>

                    <!-- 其它人员 -->
                    <vxe-column width="90" field="complete_user_name" title="完成者" :visible="false"></vxe-column>
                    <vxe-column width="90" field="create_user_name" title="创建者" :visible="false"></vxe-column>
                    <vxe-column width="90" field="cancel_user_name" title="取消者" :visible="false"></vxe-column>
                    <vxe-column width="90" field="close_user_name" title="关闭者" :visible="false"></vxe-column>
                    <vxe-column width="90" field="update_user_name" title="更新者" :visible="false"></vxe-column>

                    <!-- 概要 -->
                    <vxe-column width="120" field="type" title="任务类型"></vxe-column>
                    <vxe-column min-width="100" field="consume_days" title="任务消耗/P"></vxe-column>
                    <vxe-column min-width="100" field="wo_sum_day_consume" title="工单消耗/P"></vxe-column>
                    <vxe-column min-width="100" field="working_days" title="预期/P"></vxe-column>
                    <vxe-column min-width="100" title="小计/P">
                        <template #default="{ row }">
                            {{ Number(row.consume_days) + Number(row.wo_sum_day_consume) }}
                        </template>
                    </vxe-column>

                    <!-- 时间 -->
                    <vxe-column width="90" field="start_date[0]" title="开始时间" :visible="false"></vxe-column>
                    <vxe-column width="90" field="start_date[1]" title="截止时间"></vxe-column>
                    <vxe-column width="140" field="assign_date" title="任务实际分配时间" :visible="false"></vxe-column>
                    <vxe-column width="140" field="actual_start_date" title="实际开始时间" :visible="false"></vxe-column>
                    <vxe-column width="140" field="actual_complete_date" title="实际完成时间" :visible="false"></vxe-column>
                    <vxe-column width="140" field="cancel_date" title="任务取消时间" :visible="false"></vxe-column>
                    <vxe-column width="140" field="close_date" title="任务关闭时间" :visible="false"></vxe-column>
                    <vxe-column width="140" field="created_at" title="任务创建日期" :visible="false"></vxe-column>
                    <vxe-column width="140" field="updated_at" title="任务更新日期" :visible="false"></vxe-column>
                    
                    <vxe-column width="45" title="操作" fixed="right" align="center">
                        <template #default="{ row }">
                            <el-dropdown trigger="click">
                                <el-link icon="el-icon-more" type="primary" :underline="false"></el-link>
                                <el-dropdown-menu slot="dropdown">

                                    <el-dropdown-item @click.native.stop="$refs.taskStart.Open(row)" :disabled="[1, 4].includes(row.status) || row.children.length != 0">
                                        <el-link :underline="false" :disabled="[1, 4].includes(row.status) || row.children.length != 0">开始</el-link>
                                    </el-dropdown-item>
                                    <el-dropdown-item @click.native.stop="$refs.taskChange.Open(row)" :disabled="[4].includes(row.status) || row.children.length != 0">
                                        <el-link :underline="false" :disabled="[4].includes(row.status) || row.children.length != 0">转交</el-link>
                                    </el-dropdown-item>
                                    <el-dropdown-item @click.native.stop="$refs.taskComplete.Open(row)" :disabled="[0, 2, 4, 5, 6].includes(row.status) || row.children.length != 0">
                                        <el-link :underline="false" :disabled="[0, 2, 4, 5, 6].includes(row.status) || row.children.length != 0">完成</el-link>
                                    </el-dropdown-item>
                                    <el-dropdown-item @click.native.stop="$refs.taskClose.Open(row)" :disabled="[0, 1, 2, 4, 6].includes(row.status) || row.children.length != 0">
                                        <el-link :underline="false" :disabled="[0, 1, 2, 4, 6].includes(row.status) || row.children.length != 0">关闭</el-link>
                                    </el-dropdown-item>

                                    <el-dropdown-item @click.native.stop="$refs.taskEdit.Open(row.id)" divided>
                                        <el-link type="primary" :underline="false">修改</el-link>
                                    </el-dropdown-item>
                                    <el-dropdown-item @click.native.stop="$refs.taskSuspend.Open(row)" :disabled="[2, 4].includes(row.status) || row.children.length != 0">
                                        <el-link type="warning" :underline="false" :disabled="[2, 4].includes(row.status) || row.children.length != 0">暂停</el-link>
                                    </el-dropdown-item>
                                    <el-dropdown-item @click.native.stop="$refs.taskCancel.Open(row)" :disabled="[4, 6].includes(row.status || row.children.length != 0)">
                                        <el-link type="warning" :underline="false" :disabled="[4, 6].includes(row.status) || row.children.length != 0">取消</el-link>
                                    </el-dropdown-item>
                                    <el-dropdown-item @click.native.stop="TaskDelete([row.id])">
                                        <el-link type="danger" :underline="false">移除</el-link>
                                    </el-dropdown-item>

                                    <el-dropdown-item  @click.native.stop="$refs.taskCreate.Open(id, row.id)" divided :disabled="row.parent != null">
                                        <el-link :underline="false" :disabled="row.parent != null">新建子任务</el-link>
                                    </el-dropdown-item>
                                    <el-dropdown-item @click.native.stop="$refs.taskCreateMultiple.Open(id, row.id)" :disabled="row.parent != null">
                                        <el-link :underline="false" :disabled="row.parent != null">批量创建子任务</el-link>
                                    </el-dropdown-item>
                                </el-dropdown-menu>
                            </el-dropdown>
                        </template>
                    </vxe-column>
                </vxe-table>

            </template>

            <!-- 分页 -->
            <template slot="pager">
                <vxe-pager :total="page.total" :page-size.sync="page.size" :current-page.sync="page.current" @page-change="TableSearch()"></vxe-pager>
            </template>

        </uw-table>

        <!-- 组件 -->
        <pm-projects-drawer ref="projectsDrawer" @onChange="TableSearch" />

        <!-- 任务创建 -->
        <taskCreate ref="taskCreate" @onSave="TableSearch()"></taskCreate>
        <taskCreateMultiple ref="taskCreateMultiple" @onSave="TableSearch()" />

        <!-- 任务编辑 -->
        <taskEdit ref="taskEdit" @onSave="TableSearch()"></taskEdit>

        <!-- 任务 - 开始/转交/完成/关闭 -->
        <taskStart ref="taskStart" @onSave='TableSearch()'></taskStart>
        <taskChange ref="taskChange" @onSave='TableSearch()'></taskChange>
        <taskComplete ref="taskComplete" @onSave='TableSearch()'></taskComplete>
        <taskClose ref="taskClose" @onSave='TableSearch()'></taskClose>

        <!-- 任务 - 取消/暂停/移除 -->
        <taskCancel ref="taskCancel" @onSave='TableSearch()'></taskCancel>
        <taskSuspend ref="taskSuspend" @onSave='TableSearch()'></taskSuspend>

        <!-- 任务 - 详情 -->
        <pm-projects-task-drawer ref="taskIndex" @onChange="TableSearch()" />
    </uw-content>
</template>

<script>
import taskCreate from '@/views/projects/drawerProjectsTaskCreate'
import taskCreateMultiple from '@/views/projects/drawerProjectsTaskCreateMultiple'

import taskEdit from '@/views/projects/drawerProjectsTaskEdit'

import taskStart from '@/views/projects/drawerProjectsTaskFlowStart'
import taskChange from '@/views/projects/drawerProjectsTaskFlowChange'
import taskComplete from '@/views/projects/drawerProjectsTaskFlowComplete'
import taskClose from '@/views/projects/drawerProjectsTaskFlowClose'
import taskCancel from '@/views/projects/drawerProjectsTaskFlowCancel'
import taskSuspend from '@/views/projects/drawerProjectsTaskFlowSuspend'

export default {
    components: {
        taskCreate,
        taskCreateMultiple,
        taskEdit,
        taskStart,
        taskChange,
        taskComplete,
        taskClose,
        taskCancel,
        taskSuspend,
    },

    props: {
        id: {
            type: Number,
            require: true
        }
    },

    data () {
        return {
            loading: true,

            my: {},
            task: [],

            // 分页
            page: {
                total: 0,
                size: 50,
                current: 1,
            },

            // 检索
            search: {
                assign: [],
                status: null,
                is_overdue: null,
                name: null
            },

            // 排序
            order: {
                method: 'desc',
                column: 'id'
            },
        }
    },
    async mounted () {
        this.$http.post('/9api/my/info').then(rps => {
            this.my = rps.data
            this.search.assign = [rps.data.id]
            this.TableSearch()
        })
    },

    methods: {

        /**
        |--------------------------------------------------
        | 项目任务 - 检索
        |--------------------------------------------------
        |
        */

        TableSearch() {
            // 切换加载状态
            this.loading = true

            // 检索请求
            const append = ['wo_sum_day_consume', 'project', 'type', 'assign_user_object', 'assign_user_name', 'now_assign_user_name', 'complete_user_name', 'create_user_name', 'cancel_user_name', 'close_user_name', 'update_user_name' ]
            const hidden = ['describe']
            this.$http.post('/9api/projects-task/search', { page: this.page, search: this.search, order: this.order, append: append, hidden: hidden }).then(rps => {
                
                // 更新数据
                this.page.total = rps.data.total
                this.task = rps.data.body

                // 页码溢出
                if (this.task.length == 0 && this.page.current != 1) {
                    this.page.current = 1
                    this.TableSearch()
                }

                // 切换加载状态
                this.loading = false
            })
        },

        /**
        |--------------------------------------------------
        | 项目任务 - 移除
        |--------------------------------------------------
        |
        */

        TaskDelete(ids) {

            this.$confirm('此操作将永久删除数据，以及关联的数据，包括且不仅限以下内容: 任务工时、任务附件、子任务、统计数据等', '请谨慎操作', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'error',
                center: true
            }).then(() => {
                // 切换加载状态
                this.loading = true

                this.$http.post('/9api/projects-task/delete', { ids: ids }).then(rps => {
                    this.TableSearch()
                })
            })
        },

        TaskDeleteMultiple() {
            this.TaskDelete(this.$refs.xTable.getCheckboxRecords().map(item => item.id))
        },

        /**
        |--------------------------------------------------
        | 批量操作
        |--------------------------------------------------
        |
        */

        TaskFlowStartMultiple() {
            this.loading = true
            this.$http.post('/9api/projects-task/flow-start-multiple', { ids: this.$refs.xTable.getCheckboxRecords().map(item => item.id)}).then(rps => {
                this.TableSearch()
            })
        },

        TaskFlowSuspendMultiple() {
            this.loading = true
            this.$http.post('/9api/projects-task/flow-suspend-multiple', { ids: this.$refs.xTable.getCheckboxRecords().map(item => item.id)}).then(rps => {
                this.TableSearch()
            })
        },

        TaskFlowCancelMultiple() {
            this.loading = true
            this.$http.post('/9api/projects-task/flow-cancel-multiple', { ids: this.$refs.xTable.getCheckboxRecords().map(item => item.id)}).then(rps => {
                this.TableSearch()
            })
        },

        TaskFlowCloseMultiple() {
            this.loading = true
            this.$http.post('/9api/projects-task/flow-close-multiple', { ids: this.$refs.xTable.getCheckboxRecords().map(item => item.id)}).then(rps => {
                this.TableSearch()
            })
        }
    }
}
</script>
